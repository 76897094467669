<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card tile height="100%">
          <v-card-title class="pa-0">
            <v-app-bar elevation="2">
              <v-tabs show-arrows v-model="tab">
                <v-tab key="product"> Service / Product </v-tab>
                <v-tab key="note">
                  <span>Description</span>
                  <!-- <v-icon> mdi-note-edit-outline </v-icon> -->
                </v-tab>
                <v-tab key="photo">
                  <span>Photo</span>
                  <!-- <v-icon> mdi-camera-plus-outline </v-icon> -->
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-btn icon fab color="success" @click="close()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-app-bar>
          </v-card-title>
          <v-card-text class="pa-2">
            <v-tabs-items v-model="tab">
              <v-tab-item key="producto">
                <v-row class="pa-2">
                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="item.category"
                      id="category"
                      hide-details="auto"
                      label="Category"
                      :items="categories"
                      :rules="f_required"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="item.subcategory"
                      id="subcategory"
                      hide-details="auto"
                      label="Group"
                      :items="subcategories"
                      :rules="f_required"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="item.product_name"
                      id="product_name"
                      hide-details="auto"
                      label="Service"
                      outlined
                      required
                      dense
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-combobox
                      v-model="item.brand"
                      id="brand"
                      hide-details="auto"
                      label="Brand"
                      :items="brands"
                      :rules="f_required"
                      outlined
                      required
                      dense
                      @keyup="returnCap"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.reference"
                      id="reference"
                      hide-details="auto"
                      label="Reference"
                      :rules="f_required"
                      outlined
                      required
                      dense
                      @keyup="returnCap"
                    />
                  </v-col>
                  <v-col cols="4" md="4">
                    <v-text-field
                      class="centered-input"
                      hide-details="auto"
                      :rules="f_required"
                      v-model="item.size"
                      @keypress="onlyNumber"
                      label="Size"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="4" md="4">
                    <v-select
                      v-model="item.uom"
                      hide-details="auto"
                      :rules="f_required"
                      item-text="text"
                      item-value="value"
                      :items="uom_lst"
                      label="UOM"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="4" md="4">
                    <v-text-field
                      v-model="item.pack"
                      label="Pack"
                      class="centered-input"
                      hide-details="auto"
                      @keypress="onlyNumber"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="item.tax"
                      label="Tax"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="update_values"
                      append-icon="mdi-percent"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="cost"
                      label="Cost"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="costChange"
                      prepend-inner-icon="mdi-currency-usd"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="costTax"
                      label="Cost + tax"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="costTaxChange"
                      prepend-inner-icon="mdi-currency-usd"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="item.utility"
                      label="Utility"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="priceCalculator"
                      type="number"
                      append-icon="mdi-percent"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="price"
                      label="Price"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="priceChange"
                      prepend-inner-icon="mdi-currency-usd"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="priceTax"
                      label="Price +  Tax"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="priceTaxChange"
                      prepend-inner-icon="mdi-currency-usd"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="8" md="8">
                    <v-text-field
                      v-model="item.sku"
                      hide-details="auto"
                      label="SKU/EAN/Code"
                      append-icon="mdi-barcode-scan"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="4" md="4">
                    <v-select
                      v-model="item.active"
                      hide-details="auto"
                      :rules="f_required"
                      item-text="text"
                      item-value="value"
                      :items="active_lst"
                      label="Status"
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="note">
                <v-row>
                  <v-col cols="12" md="12" class="vueEditorCSS">
                    <vue-editor
                      v-model="item.description"
                      :editorToolbar="customToolbar"
                    ></vue-editor>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="photo" align="center" justify="center">
                <v-select
                  v-model="item.main"
                  hide-details="auto"
                  item-text="text"
                  item-value="value"
                  :items="main_lst"
                  label="Favorite"
                  outlined
                  dense
                  class="ma-3"
                />
                <v-img
                  contain
                  aspect-ratio="1"
                  class="grey lighten-2"
                  :src="productPhoto"
                  height="500"
                >
                  <v-fab-transition>
                    <v-btn
                      class="mx-2 btn-upload"
                      fab
                      light
                      small
                      color="secondary"
                      @click="chooseImage"
                    >
                      <v-icon dark> mdi-camera-plus-outline </v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-img>
                <input
                  class="file-input"
                  ref="fileInput"
                  type="file"
                  @input="onSelectFile"
                />
                <!-- <v-img
                  contain
                  aspect-ratio="1"
                  class="grey lighten-5"
                  :src="productPhoto"
                  max-height="250"
                >
                </v-img>
                <v-file-input
                  v-model="chosenPhoto"
                  counter
                  @input="PhotoToBase64"
                  label="Foto"
                  placeholder="Seleccione una imagen"
                  append-outer-icon="mdi-upload"
                  @click:append-outer="PhotoToBase64"
                  outlined
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" label small>
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ chosenFile.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input> -->
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="delete_itm(item)" v-if="!newItem">
              <span>Delete</span>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" type="submit">
              <span>Save</span>
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <!-- </v-dialog> -->
    </v-dialog>
    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Alert </v-card-title>
        <v-card-text> Delete item? </v-card-text>
        <v-card-actions>
          <v-btn
            large
            text
            color="red"
            type="button"
            @click="dialog_confirm = !dialog_confirm"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn large text color="success" type="button" @click="delete_item">
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alerts" hide-overlay persistent>
      <v-card>
        <v-alert border="left" color="orange" prominent text type="warning">
          <v-row align="center">
            <v-col class="grow"> Format error. </v-col>
            <v-col class="shrink">
              <v-btn fab x-small @click="alerts = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import { VueEditor } from "vue2-editor";
export default {
  name: "ProductForm",
  components: { VueEditor },
  data() {
    return {
      main_lst: [
        { text: "NO", value: 0 },
        { text: "SI", value: 1 },
      ],
      alerts: false,
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      reg_delete: null,
      valid: false,
      customToolbar: [
        // [{ font: [] }],
        // [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        //  [{ header: 1 }, { header: 2 }],
        //[{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
      ],
      active_lst: [
        { value: "1", text: "Activo" },
        { value: "0", text: "InActivo" },
      ],
      uom_lst: [
        { value: "UN", text: "Unidad" },
        { value: "GR", text: "Gramo" },
        { value: "KG", text: "Kilogramo" },
        { value: "LB", text: "Libra" },
        { value: "LT", text: "Litro" },
        { value: "ML", text: "Mililitro" },
        { value: "GL", text: "Galon" },
        { value: "CJ", text: "Caja" },
        { value: "OZ", text: "Onza" },
        { value: "Mes", text: "Mes" },
        { value: "Año", text: "Año" },
        { value: "Día", text: "Día" },
      ],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      productPhoto: null,
      chosenPhoto: null,
      cost: 0,
      costTax: 0,
      price: 0,
      priceTax: 0,
      utility: 0,
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    store: {
      type: String,
      default: "",
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: createItem(),
    },
    categories: {
      type: Array,
      required: false,
    },
    subcategories: {
      type: Array,
      required: false,
    },
    brands: {
      type: Array,
      required: false,
    },
    suppliers: {
      type: String,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {},
  methods: {
    PhotoToBase64() {
      if (!this.chosenPhoto) {
        this.data = "No File Chosen";
      }
      this.getBase64(this.chosenPhoto).then((data) => {
        this.compressed_image = data;
        this.productPhoto = data;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/webp");
            this.compressed_image = imageUrl;
            this.productPhoto = imageUrl;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.picture = files[0].name.toLowerCase();
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    costChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var tax_value = this.item.tax / 100 + 1;
      this.costTax = (parseFloat(this.cost) * parseFloat(tax_value)).toFixed(2);
      var utitem = (parseFloat(this.price) - parseFloat(this.cost)).toFixed(2);
      this.item.utility = ((parseFloat(utitem) / parseFloat(this.price)) * 100).toFixed(
        2
      );
    },
    update_values() {
      //var utility_value = this.item.utility / 100 + 1;
      //this.price = (parseFloat(this.cost) * parseFloat(utility_value)).toFixed(2);
      //this.priceTax = (parseFloat(this.price) * parseFloat(tax_value)).toFixed(2);
      var tax_value = this.item.tax / 100 + 1;
      this.costTax = (parseFloat(this.cost) * parseFloat(tax_value)).toFixed(2);
      this.price = (parseFloat(this.priceTax) / parseFloat(tax_value)).toFixed(2);

      var utitem = (parseFloat(this.price) - parseFloat(this.cost)).toFixed(2);
      console.log("DIFERENECIA", utitem);
      this.item.utility = ((parseFloat(utitem) / parseFloat(this.price)) * 100).toFixed(
        2
      );
    },
    costTaxChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var tax_value = parseFloat(this.item.tax) / 100 + 1;
      this.cost = (parseFloat(this.costTax) / parseFloat(tax_value)).toFixed(2);
    },
    // priceCalculator() {
    //   var utility_value = this.item.utility / 100 + 1;
    //   var tax_value = this.item.tax / 100 + 1;
    //   this.price = (parseFloat(this.cost) * parseFloat(utility_value)).toFixed(2);
    //   this.priceTax = (parseFloat(this.price) * parseFloat(tax_value)).toFixed(2);
    // },
    priceCalculator() {
      var porcent = (100 - this.item.utility) / 100;
      console.log(porcent);
      this.price = (parseFloat(this.cost) / porcent).toFixed(2);
      var tax_value = this.item.tax / 100 + 1;
      this.priceTax = (parseFloat(this.price) * parseFloat(tax_value)).toFixed(2);
    },
    priceChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var tax_value = this.item.tax / 100 + 1;
      this.priceTax = this.price * tax_value;
      var utitem = (parseFloat(this.price) - parseFloat(this.cost)).toFixed(2);
      this.item.utility = ((parseFloat(utitem) / parseFloat(this.price)) * 100).toFixed(
        2
      );
    },
    priceTaxChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var tax_value = this.item.tax / 100 + 1;
      this.price = (parseFloat(this.priceTax) / parseFloat(tax_value)).toFixed(2);

      var utitem = (parseFloat(this.price) - parseFloat(this.cost)).toFixed(2);
      this.item.utility = ((parseFloat(utitem) / parseFloat(this.price)) * 100).toFixed(
        2
      );
      //this.item.utility = parseFloat((utitem - 1) * 100);
    },

    getImagePhoto: function (account, store, src) {
      var url = "https://" + this.$store.getters.getHost + "/" + account + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },

    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        var normalizer = 1;
        switch (this.item.uom) {
          case "LB":
            normalizer = 453;
            this.item.normalized_uom = "GR";
            break;
          case "LT":
            normalizer = 1000;
            this.item.normalized_uom = "ML";
            break;
          case "KG":
            normalizer = 1000;
            this.item.normalized_uom = "GR";
            break;
          case "OZ":
            normalizer = 28.34;
            this.item.normalized_uom = "GR";
            break;
          case "GL":
            normalizer = 3785.4;
            this.item.normalized_uom = "ML";
            break;
          case "ML":
            normalizer = 1;
            this.item.normalized_uom = "ML";
            break;
          case "UN":
            normalizer = 1;
            this.item.normalized_uom = "UN";
            break;
          case "CJ":
            normalizer = 1;
            this.item.normalized_uom = "CJ";
            break;
          case "GR":
            normalizer = 1;
            this.item.normalized_uom = "GR";
            break;
          case "Mes":
            normalizer = 1;
            this.item.normalized_uom = "Mes";
            break;
          case "Año":
            normalizer = 1;
            this.item.normalized_uom = "Año";
            break;
          case "Día":
            normalizer = 1;
            this.item.normalized_uom = "Día";
            break;
          default:
            normalizer = 1;
            this.item.normalized_uom = "UN";
        }
        this.item.normalized_size = parseFloat(this.item.size) * normalizer;
        this.item.cost = this.cost;
        this.item.price = this.priceTax;
        this.item.cost_uom =
          parseFloat(this.item.cost) / parseFloat(this.item.normalized_size);
        this.item.price_uom =
          parseFloat(this.priceTax) / parseFloat(this.item.normalized_size);

        this.item.store = this.store;

        this.item.tax_base = parseFloat(this.price) * parseFloat(this.item.pack);
        this.item.price_amount = parseFloat(this.price) * parseFloat(this.item.pack);
        this.item.price_value = parseFloat(this.priceTax) * parseFloat(this.item.pack);

        this.item.tax_value =
          (parseFloat(this.priceTax) - parseFloat(this.price)) *
          parseFloat(this.item.pack);

        var qry = {
          account: this.$store.state.profile.account,
          table: this.table,
          data: [this.item],
        };
        if (this.compressed_image) {
          qry.photo = this.compressed_image;
        }
        console.log(qry);
        webserver("put_table", qry, () => {
          this.compressed_image = null;
          this.loading_status = false;
          this.$emit("refresh", true);
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: [
          { field: "account", operator: "=", value: this.item.account },
          { field: "store", operator: "=", value: this.item.store },
          { field: "code", operator: "=", value: this.item.code },
        ],
      };
      console.log(qry);
      webserver("delete_record", qry, () => {
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", true);
    },
  },
  watch: {
    dialog: function () {
      // if (!e.picture) {
      //   this.compressed_image = null;
      // }
      ////console.log(e);
    },
    item: function (e) {
      console.log(e);
      // this.item.supplier = this.suppliers.find(
      //   (prv) => prv.code.toUpperCase() === this.item.supplier.toUpperCase()
      // );
      this.cost = parseFloat(this.item.cost).toFixed(2);
      this.priceTax = parseFloat(this.item.price).toFixed(2);
      this.update_values();
      this.compressed_image = null;
      if (e.picture) {
        this.productPhoto = this.getImagePhoto(e.account, "000000", e.picture);
      } else {
        this.productPhoto = null;
      }
    },
  },
};
</script>

<style>
.btn-upload {
  top: 100px;
}

.bar-top {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 50;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}
</style>
