var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-top":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":"","md10":""}},[_c('GChart',{attrs:{"type":"PieChart","data":_vm.perSupplier,"options":_vm.chartOptions}}),_c('v-sheet',{staticClass:"pa-2",attrs:{"color":"white","elevation":"20"}},[_c('v-data-table',{staticClass:"table-cursor mt-3",attrs:{"headers":_vm.headers[_vm.$vuetify.breakpoint.xsOnly],"items":_vm.items,"no-data-text":"No hay productos registrados","items-per-page":-1,"item-key":"store,code","mobile-breakpoint":"0","group-by":"storeName","show-group-by":"","search":_vm.search,"sort-by":"category,product_name"},on:{"current-items":_vm.updateChart,"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-text-field',{attrs:{"hide-details":"","append-icon":"mdi-magnify","outlined":"","placeholder":"Buscar producto ...","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.supplier_selected.code)?_c('v-btn',{attrs:{"color":"orange","fab":"","x-small":"","dark":""},on:{"click":function($event){_vm.dialog_smart = !_vm.dialog_smart}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)]},proxy:true},(_vm.item.picture !== '')?{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.getImagePhoto(item.account, '000000', item.picture)}})],1)]}}:null,{key:"item.store",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.storeName)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.product_name))]),(_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_c('small',[_vm._v(" ("+_vm._s(item.category)+")")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.size)+" "+_vm._s(item.uom)+" ")])]):_vm._e()]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.size + " " + item.uom)+" "),_c('br')]}},{key:"item.normalized_size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.cost / item.size).toFixed(2))+" "),_c('br')]}},{key:"item.cost_uom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.cost_uom).toFixed(2))+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.cost).toFixed(2))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.price).toFixed(2))+" ")]}},{key:"item.costlp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.costlp).toFixed(2))+" ")]}},{key:"item.cost_max",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.cost_max).toFixed(2))+" ")]}},{key:"item.cost_avg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.cost_avg).toFixed(2))+" ")]}}],null,true)}),_c('ProductForm',{attrs:{"table":_vm.table,"item":_vm.item,"dialog":_vm.dialog,"newItem":_vm.newItem,"categories":_vm.categories,"store":_vm.store,"subcategories":_vm.subcategories,"brands":_vm.brands,"suppliers":this.supplier_selected.code},on:{"close":function($event){_vm.dialog = false},"refresh":function($event){return _vm.refresh()}}}),_c('v-dialog',{attrs:{"right":"","width":_vm.smart_width},model:{value:(_vm.dialog_smart),callback:function ($$v) {_vm.dialog_smart=$$v},expression:"dialog_smart"}},[_c('v-toolbar',{staticClass:"fixed-bar",attrs:{"dark":"","color":"#128C7E"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","fab":""},on:{"click":function($event){_vm.s_expand = !_vm.s_expand}}},[_c('v-icon',[_vm._v("mdi-arrow-split-vertical")])],1),_c('v-toolbar-title',[_vm._v("Smart List")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.dialog_smart = !_vm.dialog_smart}}},[_vm._v("mdi-close")])],1),_c('v-toolbar',{staticClass:"fixed-bar-b",attrs:{"color":"teal lighten-4"}},[_c('v-select',{attrs:{"rounded":"","items":_vm.smart_categories,"outlined":"","dense":"","hide-details":"auto"},on:{"change":_vm.changeMenu},model:{value:(_vm.catSelected),callback:function ($$v) {_vm.catSelected=$$v},expression:"catSelected"}}),_c('v-spacer'),_c('v-btn',{attrs:{"x-small":"","color":"warning","fab":""},on:{"click":function($event){return _vm.newForm()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-data-table',{staticClass:"table-cursor elevation-1",attrs:{"headers":_vm.headers[!_vm.s_expand],"items":_vm.smart_lst_selected,"items-per-page":15,"item-key":"store,code","sort-by":"name","search":_vm.search_smart,"mobile-breakpoint":"0","loading-text":"Cargando ..."},scopedSlots:_vm._u([(_vm.item.picture !== '')?{key:"item.picture",fn:function(ref){
var item = ref.item;
return [(item.picture)?_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.getImagePhoto(item.account, '000000', item.picture)}})],1):_vm._e()]}}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.product_name))]),(_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_c('small',[_vm._v(" ("+_vm._s(item.category)+")")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.size)+" "+_vm._s(item.uom)+" ")])]):_vm._e()]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.size + " " + item.uom)+" "),_c('br')]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.price).toLocaleString(2))+" ")]}},{key:"item.addto",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.add_to_supplier(item)}}},[_vm._v(" mdi-arrow-left ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit_smart_lst(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)}),_c('v-bottom-navigation',{attrs:{"dark":"","app":""}},[_c('v-toolbar',{attrs:{"color":"#128C7E"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","append-icon":"mdi-magnify","outlined":"","rounded":"","dense":"","placeholder":"Buscar producto Smart ..."},on:{"keypress":function($event){return _vm.update_selected()}},model:{value:(_vm.search_smart),callback:function ($$v) {_vm.search_smart=$$v},expression:"search_smart"}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading_status),callback:function ($$v) {_vm.loading_status=$$v},expression:"loading_status"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Cargando ... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }